export default defineNuxtRouteMiddleware(async (to) => {
  const auth = useAuth();
  const config = useRuntimeConfig();
  const client = useCookie('client-data', { readonly: true });

  if (auth.client) return;

  if (!client.value) {
    auth.client = {
      clientID: config.public.ssoClientId,
      redirectURI: config.public.ssoRedirectUri,
      isThirdParty: false,
    };
  } else {
    auth.client = client.value as unknown as typeof auth.client;
  }
});
